import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import MainCarousel from "../components/MainCarousel";

export default function Home() {
  return (
    <main>
      <Row className="justify-content-md-center my-1">
        <Col style={{ margin: "0px", maxWidth: "68vh" }} className="px-4 my-2">
          <div style={{ border: "5px solid white" }}>
            <MainCarousel />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-md-center my-3" style={{ fontFamily: "Montserrat", fontSize: "min(3vmin, 0.8em)" }}>
        <Col>© 2023 by Nicholas Wilson</Col>
      </Row>
    </main>
  );
}
