import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import "../App.css";
import Modal from "react-bootstrap/esm/Modal";
import { useState, useEffect, useRef } from "react";
import CloseButton from "react-bootstrap/esm/CloseButton";
import ImageGallery from "./ImageGallery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import ScrollIntoView from "react-scroll-into-view";
var white = require("../images/white.png");

export default function ImageCard(props: any) {
  return (
    <Link to={`${props.index}`}>
      <div
        className="imageCard hoverHighlight "
        style={{
          width: "100%",
          border: (props.data.status==="Sold" ? "5px solid #d6d0d5" : "5px solid white"),
          backgroundColor: (props.data.status==="Sold" ? '#d6d0d5': "white"),
          borderRadius: "5px",
          boxShadow: "3px 3px 2px #d9d9d9",
          cursor: "pointer",
          fontFamily: "Montserrat",
          fontSize: "0.8rem",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: (props.data.status==="Sold" ? '#d6d0d5': "white"),
            width: "100%",
          }}
        >
          <LazyLoadImage
            placeholder={
              <img
                src={white}
                style={{
                  aspectRatio: "1/1",
                  minWidth: "100%",
                  height: "auto",
                  width: "100%",
                  backgroundColor: "rgb(100,100,100)",
                }}
              ></img>
            }
            style={{
              borderRadius: "2px",
              boxShadow: "1px 1px 1px #d9d9d9",
              minWidth: "100%",
              height: "auto",
              width: "100%",
              backgroundColor: "rgb(100,100,100)",
            }}
            //src={"/icon3.png"}
            src={"https://d1gbvgaxn6iptv.cloudfront.net/images/" + props.data.urls[0]}
            alt={props.data.title}
            effect="blur"
          ></LazyLoadImage>

          <Row style={{ margin: "20px", minHeight: "30px" }}>
            <Col md={6}  sm={6} xs={6} style={{ marginTop: "0.2em", padding: "0px" , fontSize: '1.15em'}}>
              {props.data.dimensions}
            </Col>
            <Col md={6}  sm={6} xs={6} style={{ marginTop: "0.2em", padding: "0px", color: props.data.status=== "Available" ? "blue" : (props.data.status==="Sold" ? '#995d88': "black"), fontSize: '1.15em'}}>
              {props.data.status}
            </Col>
            <Col md={12} sm={12} xs={12} style={{ marginTop: "1.8em", padding: "5px", fontSize: '1.5em'}}>
              {props.data.title}
            </Col>
          </Row>
        </div>
      </div>
    </Link>
  );
}
