import "./App.css";
import Container from "react-bootstrap/esm/Container";
import HeaderBar from "./components/HeaderBar";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <style>{"body { background-color: rgb(242,240,240); }"}</style>
      <Container>
        <HeaderBar />
        <Outlet />
      </Container>
    </div>
  );
}

export default App;
