import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
var imageName = require("../images/nick_with_painting.jpg");
var wave = require("../images/art1.jpg");
var stall = require("../images/stall.jpg");
var wave2 = require("../images/vulva_2.jpg");

class MainCarousel extends Component {
  render() {
    return (
      <Carousel
        showThumbs={false}
        showStatus={false}
        stopOnHover={true}
        autoPlay={true}
        interval={6500}
        infiniteLoop={true}
        swipeable={true}
      >
        <div>
          <img src={imageName} style={{ maxWidth: "100%", maxHeight: "100%" }} alt="Image of Visnick with Brain Painting"></img>
          <p className="legend">
            Visnick is a contemporary self-taught artist based in London. He is currently working on a series of 3-dimensional
            seascapes for his first upcoming exhibition, and open to taking commissions.
          </p>
        </div>
        <div>
          <img src={stall} style={{ maxWidth: "100%", maxHeight: "100%" }} alt="Image of Visnick at Stall"></img>
          <p className="legend">
            The latest art for sale from Visnick is ocean sculptures on canvas made from recyled clothing, as well as seascape paintings on wood that have both been sold at in-person markets.
          </p>
        </div>
        <div>
          <img src={wave} style={{ maxWidth: "100%", maxHeight: "100%" }} alt="Image of Art on Paper"></img>
          <p className="legend">
            Work as a professional artist began in earnest in 2022. In the years before, Nick mainly worked on paper, painting
            murals usually themed around female dancing figures. Not all of them have survived.
          </p>
        </div>
      </Carousel>
    );
  }
}

export default MainCarousel;
