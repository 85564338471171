import { useRef, useEffect } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "../App.css";
import ImageGallery from "../components/ImageGallery";
import "react-lazy-load-image-component/src/effects/blur.css";
import Container from "react-bootstrap/esm/Container";
import CloseButton from "react-bootstrap/esm/CloseButton";

function ImagePage() {
  const location = useLocation();
  let history = useNavigate();
  const componentRef = useRef(
    require(`../data/${location.pathname.split("/")[1]}.json`).paintings[parseInt(location.pathname.split("/")[2])]
  );
  const { current: data } = componentRef;
  const fieldRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // @ts-ignore: Object is possibly 'null'.
    fieldRef.current.scrollIntoView({
      behavior: "smooth",
    });
  });

  return (
    <div
      style={{
        height: "100%",
        backgroundColor: "rgb(4,5,6)",
        overflowX: "hidden",
      }}
    >
      <Container
        fluid
        style={{
          fontFamily: "Montserrat",
          fontSize: "1.6rem",
          color: "rgb(245,240,240)",
          position: "fixed",
          zIndex: "100",
          backgroundColor: "rgb(4,5,6)",
          width: "100%",
          padding: "0.3em",
        }}
      >
        <Row className="justify-content-between">
          <Col><a className="imagepage" href="/">VISNICK ART</a></Col>
          <Col>
            <CloseButton variant="white" style={{ float: "right" }} onClick={() => history(-1)} />
          </Col>
        </Row>
      </Container>

      <Row
        className="mx-auto"
        style={{
          marginTop: "3.3rem",
          backgroundColor: "rgb(4,5,6)",
          color: "rgb(245, 238, 237)",
          fontFamily: "Montserrat",
          fontSize: "1.1rem",
          fontWeight: "80",
        }}
      >
        <Col className="justify-contents-center align-items-center text-center col-md-5 mx-auto offset-md-1 px-5" sm={12} xs={12}>
          <ImageGallery data={data} />
        </Col>
        <Col md={3} xs={12} sm={6} className="py-2 px-2 mx-auto" style={{ backgroundColor: "black" }}>
          <Row className="py-2 mx-2">"{data.title}"</Row>
          <Row className="py-2 mx-2">{data.dimensions}</Row>
          <Row className="py-2 mx-2">{data.medium}</Row>
          <Row ref={fieldRef} className="py-2 mx-2">
            ***{data.status}***
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ImagePage;

/*
  <Row
          style={{
            textDecoration: "underline",
            marginTop: "0.2em",
            fontFamily: "Montserrat",
            fontSize: "1.3rem",
            color: "rgb(230,230,230)",
            fontWeight: "bold",
            margin: "0.2em  ",
          }}
          className="justify-contents-center align-items-middle text-center mx-auto"
        >
          <Col md={6} sm={6} xs={6}>
            NEXT
          </Col>
          <Col md={6} sm={6} xs={6}>
            PREV
          </Col>
        </Row>*/
