import React, { Component } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

var insta = require("../images/instagram.png");
var tiktok = require("../images/tiktok.png");
var twitter = require("../images/twitter.png");

class SocialMedia extends Component {
  render() {
    return (
      <Row className="justify-content-center my-0">
        <Col md={4} xs={4}>
          <a href="https://www.instagram.com/visnick_art/">
            <img
              className="linkHover"
              src={insta}
              style={{
                maxWidth: "min(15vmin, 5rem)",
                maxHeight: "min(15vmin, 5rem)",
                margin: "10px",
                borderRadius: "50%",
                border: "2px solid white",
              }}
              alt="mysvg"
            ></img>
          </a>
        </Col>
      </Row>
    );
  }
}

export default SocialMedia;
