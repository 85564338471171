import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import SocialMedia from "./SocialMedia";
import { NavLink, Link } from "react-router-dom";
import "../App.css";

function HeaderBar() {
  return (
    <div>
      <Row style={{ fontFamily: "Montserrat", fontSize: "min(10vmin, 3rem)" }}>
        <Col className="my-1 ">
          {" "}
          <Link to="/">VISNICK&nbsp;&nbsp;ART</Link>
        </Col>   
      </Row><SocialMedia />
      <Row
        className="align-items-center text-center my-0"
        style={{
          fontFamily: "Montserrat",
          fontSize: "1rem",
          fontWeight: "bold",
        }}
      >
          <Col className="my-1" md={{ span: 2 , offset: 1}}>
            <NavLink end to="/" >
            About
          </NavLink>
        </Col>
        <Col className="my-1" md={{ span: 2 }}>
          <NavLink end to="/aistilllives" >
            AI Still Lives
          </NavLink>
        </Col>
        <Col className="my-1" md={{ span: 2 }}>
          <NavLink end to="/seascapes">
            Seascapes
          </NavLink>
        </Col>
        <Col className="my-1" md={{ span: 2 }}>
        <NavLink end to="/standalones">
            Standalones
          </NavLink>
        </Col>
        <Col className="my-2" md={{ span: 2,  }}>
          <NavLink to="/contact">Contact</NavLink>
        </Col>
  
      </Row>
    </div>
  );
}

export default HeaderBar;
