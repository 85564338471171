import "../App.css";
import Slider from "react-slick";
import { Key } from "react";
import InnerImageZoom from "react-inner-image-zoom";

export default function ImageGallery(props: any) {
  const isMobile = window.screen.width < 600;
  return (
    <Slider
      customPaging={(i) => (
        <div
          style={{
            color: "white",
            padding: "4px",
            cursor: "pointer",
          }}
        >
          {props.data.views[i]}
        </div>
      )}
      dotsClass={"no-bullets"}
      dots={true}
      adaptiveHeight={false}
    >
      {props.data.urls.map(function (d: Object, idx: Key) {
        if (isMobile) {
          return (
            <>
              <InnerImageZoom
                key={idx}
                zoomScale={0.3}
                src={"https://d1gbvgaxn6iptv.cloudfront.net/images/" + props.data.urls[idx]}
                fullscreenOnMobile={false}
                hideCloseButton={true}
                hideHint={true}
              />
              <p>Tap Image to toggle Zoom &#x1F50E;</p>
            </>
          );
        } else {
          return <img src={"https://d1gbvgaxn6iptv.cloudfront.net/images/" + props.data.urls[idx]} key={idx} />;
        }
      })}
    </Slider>
  );
}
