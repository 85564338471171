import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Form from "react-bootstrap/esm/Form";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

export default function Contact() {
  const form: any = useRef();

  const sendEmail = (e: any) => {
    e.preventDefault();
    alert("Thank you for submitting your message. I will get back to you as soon as possible.");

    emailjs
      .sendForm(
        process.env["REACT_APP_SERVICE_ID"] ?? "",
        process.env["REACT_APP_TEMPLATE_ID"] ?? "",
        form.current,
        process.env["REACT_APP_EMAIL_KEY"] ?? ""
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div
      className="mx-auto justify-content-left align-items-left text-left"
      style={{
        justifyContent: "left",
        maxWidth: "600px",
        marginTop: "20px",

        fontFamily: "Montserrat",
        fontSize: "0.9rem",
        fontWeight: "bold",
      }}
    >
      <Row className="text-start align-items-left justify-contents-left" style={{ justifyContent: "left", margin: "20px" }}>
        <Col md={12} className="text-start align-items-left justify-contents-left">
          Hello, my email is{" "}
          <a style={{ color: "rgb(147, 148, 214)" }} href="mailto:visnickart@gmail.com">
            visnickart@gmail.com
          </a>{" "}
          , alternatively you can use the form below to contact me or contact me on instagram. If you wish to purchase one of my available original artworks,
          or would like to discuss a commission, please do not hesitate to get in touch.
        </Col>
      </Row>
      <Row>
        <Form ref={form} onSubmit={sendEmail}>
          <Form.Group as={Row} className="mb-3" controlId="from_name">
            <Form.Label column sm="2">
              Name
            </Form.Label>
            <Col sm="10">
              <Form.Control name="from_name" style={{ fontFamily: "Roboto" }} placeholder="" />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="reply_to">
            <Form.Label column sm="2">
              Email
            </Form.Label>
            <Col sm="10">
              <Form.Control name="reply_to" style={{ fontFamily: "Roboto" }} placeholder="email@example.com" />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="message">
            <Form.Label column sm="2"></Form.Label>
            <Col sm="10">
              <Form.Control name="message" style={{ fontFamily: "Roboto" }} as="textarea" rows={4} placeholder="" />
            </Col>
          </Form.Group>

          <Button style={{ float: "right" }} variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Row>
    </div>
  );
}
