import Row from "react-bootstrap/esm/Row";
import ImageCard from "../components/ImageCard";
import { Key } from "react";
import { Col } from "react-bootstrap";

export default function SeaScapes() {
  const data = require("../data/seascapes.json").paintings;
  return (
    <Col>
    <Row
      className="align-items-center text-center my-0"
      style={{
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: "bold",
      }}>
    <Col style={{ fontSize:'0.8em', margin: "2em"}}>&#9755; Touch Image For Details &#9754;</Col>
  </Row>
    <Row>
      <section id="photos">
        {data.map(function (d: Object, idx: Key | null | undefined) {
          return <ImageCard data={d} key={idx} index={idx} gallery={"seascapes"} />;
        })}
      </section>
    </Row>
    </Col>
  );
}
