import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Home from "./routes/Home";
import SeaScapes from "./routes/SeaScapes";
import Standalones from "./routes/Standalones";
import ImagePage from "./routes/ImagePage";
import AiStillLives from "./routes/AiStillLives";
import Contact from "./routes/Contact";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="/aistilllives" element={<AiStillLives />} />
          <Route path="/seascapes" element={<SeaScapes />} />
          <Route path="/standalones" element={<Standalones />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
        <Route path="/aistilllives/:id" element={<ImagePage />} />
        <Route path="/seascapes/:id" element={<ImagePage />} />
        <Route path="/standalone/:id" element={<ImagePage />} />
      </Routes>
    </BrowserRouter>
    ,
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
